.container {
    max-width: var(--main-container);
    padding: 0 10px;
    margin: -60px auto -50px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.labelStyle {
    font-size: 16px;
    font-weight: 500}


.captcha {
    transform: scale(1);
    transform-origin: 50% 50%;
    margin-bottom: 10px;
}
.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
}



.errorMessage {
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-main-theme);
    font-size: 12px;
}

.inputField {
    border: 1px solid var(--color-footer);
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: var(--color-main-bubble);
}

.inputField:focus-within {
    background-color: #ffffff;

    outline: none;
}

.form {
    display: flex;
    flex-direction: column;
    max-width: 304px;
    margin: auto;
}

.button {
    height: 2em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    color: var(--color-main-theme);
    border: none;
    background-color: #cac5c5;
    transition: all .3s ease-in-out;
}

.button:hover {
    color: #b4b4b4;
    background-color: #007aff;
}

.button:disabled, .button:disabled:active {
    color: var(--color-dark-grey);
    background-color: darkgray;
    cursor: auto;
}

.button:active {
    color: #ffffff;
    background-color: #b4b4b4;
}


@media screen and (max-width: 720px) {
    .container {
        margin: -40px auto -100px;
    }


}


@media screen and (max-width: 330px) {
    .form {
        max-width: 90%;
    }

    .captcha {
        transform: scale(.65) translate(-30%, -25%);

        margin: auto;
    }
}
