.container {
   /* position: relative;*/
    margin: 0 auto -100px;
    /*max-width: var(--main-container);*/
    padding: 0 10px;
    background-color: transparent;
}



.videoSection {
    position: relative;
}
.videoContainer {
    width: 70%;
    margin: auto;
    position: relative;

}

.playButton {
    opacity: 0;
    transition: all .3s ease-in-out;
    width: 50%;
    height: 50%;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
}

.playButtonPause {
    opacity: 1;
}

.pauseButton {
    bottom: 50px;
    left: 250px;
    position: absolute;
}


.video {
    width: 100%;
    z-index: -10;
    overflow: hidden;
    border: 1px solid var(--color-dark-grey);
    border-radius: 10px;
}

.pl {
    cursor: pointer;
    transition: opacity .3s ease-in-out;
}
.player {
    opacity: 1;
}



.videoPaused {
    opacity: 0.4;
}


.contentSection {
    max-width: var(--main-container);
    margin: auto;
}

.topDescription {
    font-size: clamp(18px, 3vw, 31px);
    font-weight: 600;
    margin: 0;
}

.topDescription:nth-child(2) {
    margin-bottom: clamp(80px, 12vw, 180px);
}

.circleContainer {
    margin: auto auto 150px;
    width: 30%;
    aspect-ratio: 1/1;
    max-width: var(--main-container);
    font-size:0;
    border-radius:50%;
    background-color: var(--color-main-bubble);
    position: relative;
}

.deg1, .deg2, .deg3, .deg4,  .deg6, .deg7, .deg8, .deg9 {
    display:inline-block;
    width:calc(100% / 3);
    height:calc(100% / 3);
    text-align:center;
    border-radius:50%;
    font-size: 10px;
    position: absolute;
}

.iconDescription {
    font-size: clamp(12px, 2vw, 18px);
    white-space: nowrap;
    font-weight: 600;
    margin: 0;
}

.icon {
    border-radius: 50%;
    background-color: var(--color-main-theme);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding:  clamp(20px, 3vw, 40px);
    position: relative;
}


.iconImg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:clamp(30px, 4vw, 60px);
    position: absolute;
}

.deg5{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.centerIcon {
    /*padding:  clamp(30px, 10vw, 180px);*/
    position: relative;
}

.centerIconImg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:clamp(120px, 20vw, 280px);
    position: absolute;
}


.iconWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.icon1Wrap, .icon3Wrap,.icon4Wrap,.icon6Wrap,.icon7Wrap, .icon9Wrap {
    flex-direction: row;
}

.deg2 {
  top: -30%;
    left: 50%;
    transform: translateX(-50%);
}

.deg1 {
    top: 0;
    right: 90%;
}

.deg3 {
    top: 0;
    left: 95%;
}

.deg4  {
    top: 50%;
    right: 90%;
    transform:translate(-50%, -50%);
}

.deg6  {
    top: 50%;
    left: 90%;
    transform:translate(50%, -50%);
}

.deg7 {

    top: 75%;
    right: 90%;
}

.icon7 {
    transform: translate(-55%, -55%);
}

.deg9 {
    top: 75%;
    left: 90%;
}

.deg8 {
    bottom: -30%;
    left: 50%;
    transform: translateX(-50%);
}

.sqm {
color: #fff;
font-size:  clamp(28px, 3vw, 42px);
    font-weight: 600;
position: absolute;
bottom: 25%;
    left: 25%;
}

.square {
    font-size:  clamp(12px,  2vw, 18px);


}

@media screen and (max-width: 1024px) {
    .sqm {
        left: 15%;
    }

    .container {
        margin-bottom: -100px;
    }
}

@media screen and (max-width: 760px) {
    .topDescription:first-child {
        padding-top: 20px;
    }
}

{

}

@media screen and (max-width: 540px) {

    .topDescription:first-child {
        padding-top: 0;
    }
    .centerIconImg {
        width: 90px;
        transform: translate(-50%, -60%);
    }

    .icon {
        padding:  20px;
    }


    .iconImg {
        width: 30px;
    }


    .deg2 {
        top: -50%;
        transform: translateX(-50%);
    }

    .deg1 {
        top: -10%;
        right: 110%;
    }

    .deg3 {
        top: -10%;
        left: 120%;
    }

    .deg4  {
        top: 50%;
        right: 110%;
        transform:translate(-50%, -50%);
    }

    .deg6  {
        top: 50%;
        left: 110%;
        transform:translate(50%, -50%);
    }

    .deg7 {
        top: 75%;
        right: 100%;
    }

    .icon7 {
        transform: translate(-55%, -55%);
    }

    .deg9 {
        top: 75%;
        left: 100%;
    }

    .deg8 {
        bottom: -30%;
        left: 50%;
        transform: translateX(-50%);
    }

    .sqm {
        font-size:  22px;
        font-weight: 600;
        position: absolute;
        bottom: 20%;
        left: 20%;
    }

    .topDescription {
        font-size: 11px;
    }
    .iconDescription {
        font-size: 10px;
        font-weight: 400;
    }

    .container {
        margin-bottom: -200px;
    }

}

@media screen  and (max-width: 320px) {
    .centerIconImg {
        width: 75px;
        transform: translate(-50%, -55%);
    }

    .icon {
        /*background-size: 70%;*/

        /*padding:  clamp(20px, 3vw, 40px);*/
        padding:  15px;

    }


    .iconImg {
        width: 20px;
    }


    .deg2 {
        top: -60%;
        transform: translateX(-50%);
    }

    .deg1 {
        top: -10%;
        right: 120%;
    }

    .deg3 {
        top: -10%;
        left: 130%;
    }

    .deg4  {
        top: 50%;
        right: 120%;
        transform:translate(-50%, -50%);
    }

    .deg6  {
        top: 50%;
        left: 120%;
        transform:translate(50%, -50%);
    }

    .deg7 {
        top: 80%;
        right: 110%;
    }

    .icon7 {
        transform: translate(-55%, -55%);
    }

    .deg9 {
        top: 80%;
        left: 110%;
    }

    .deg8 {
        bottom: -30%;
        left: 50%;
        transform: translateX(-50%);
    }

    .sqm {
        font-size:  16px;
        font-weight: 400;
        position: absolute;
        bottom: 18%;
        left: 15%;
    }

    .topDescription {
        font-size: 11px;
    }
    .iconDescription {
        font-size: 10px;
        font-weight: 400;
    }
.container {
    margin-bottom: -50px;
}
}

@media screen and (max-width: 640px ) {
    .videoContainer {
        width: 100%;
    }
}
