.container {
    text-align: center;
    margin: 0 auto 10px auto;
   height: 700px;
    position: relative;
}
.video {
    /*width: 100vw;*/
    width: 100%;
    z-index: -10;
    /*height: 50vh;*/
    overflow: hidden;
    /*position: absolute;*/
    /*left: 0;*/
}

.spinner {
    position: absolute;
    top: 150px;
    left: 48%;
}
.heroImage {
    /*background-image: url("../../assets/images/hero_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 80%;
    width: 100%;*/
 /*   background-position: center;*/
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    top: -50%
}

.img {
    width: 100%;
    height: 80%;
    z-index: 10;
    position: relative;

}

.heroDescription {
    position: absolute;
   /* background-image: url("../../assets/images/header_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;*/
    /*transform: translateY(-100px);*/
    max-height: 300px;
    height: clamp(40%, 40vw, 80%);
    width: 100%;
    z-index: -1;
    bottom: 0;
}

.videoCover {
    background-color: white;
    width: 100%;
    height: 100%;
    transform: translateY(-22%);
    z-index: -2;
    position: relative;
}

.descrContainer {
    position: absolute;
    width: 100%;
    top: 20%;
    margin: auto;
    z-index: 10;

}

.header {
    margin: 0;
    font-weight: 600;
    z-index: 10;
    font-size: clamp(18px, 2vw, 28px);
}

.villageBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.village {
    margin: 10px 5px;
    font-size: clamp(12px, 2vw, 22px);
}

.divider {
    border-top: 1px solid var(--color-main-theme);
    width: 20%;
    height: 1px;
}


@media only screen and (max-width: 1540px)  {
    .heroDescription {
        transform: translateY(-23%);
    }
    .container {
        height: 700px;
    }
    .videoCover {
        height: 100%;
    }
}

@media only screen and (max-width: 1350px)  {
    .heroDescription {
        /*transform: translateY(-25vh);*/
        /*transform: translateY(-280px);*/
        transform: translateY(30%);
    }

    .videoCover {
        height: 100%;
    }
    .container {
        height: 600px;
    }
    .heroImage {
        top: 0%;
    }


    .spinner {
        position: absolute;
        top: 150px;
        left: 48%;
    }

}

@media only screen and (max-width: 980px)  {
    .heroImage {
        /*top: -40%;*/
    }
    .heroDescription {
        transform: translateY(00%);
    }

    .spinner {
        position: absolute;
        top: 80px;
        left: 48%;
    }
}

@media only screen and (max-width: 720px)  {
    .heroDescription {
        transform: translateY(35%);

    }
    .container {
        height: 280px;
    }

}
@media only screen and (max-width: 585px) {
    .heroImage {
        background-position: top;
    }
    .heroDescription {
        /*transform: translateY(20%);*/

    }
    .container {
        height: 350px;
    }
}

@media only screen and (max-width: 490px) {

    .heroDescription {
        transform: translateY(-20%);

    }
    .container {

    }
}


@media only screen and (max-width: 460px) {
    .videoCover {
        height: 10%;
    }

    .heroDescription {
        transform: translateY(17%);


    }
    .container {
        height: 300px;
    }
    .header {
        font-size: 12px;
    }
}


@media only screen and (max-width: 390px) {

    .spinner {
        position: absolute;
        top: 55px;
        left: 45%;
    }
    .videoCover {
        height: 50%;
        z-index: -1;
    }


    .header {
font-size: 12px;
    }


    .village {
        margin: 5px 5px;
    }


    .heroDescription {
        /*z-index: 1;*/
        transform: translateY(-10%);

    }
    .container {
height: 300px;
    }
}

@media only screen and (max-width: 320px) {

    .heroDescription {
        transform: translateY(25%);
    }
    .videoCover {
        height: 0%;
    }
    .container {
        height: 200px;
    }


    .spinner {
        position: absolute;
        top: 40px;
        left: 45%;
    }
}

@media only screen and (max-width: 280px) {

    .heroDescription {
        transform: translateY(0%);
    }
    .videoCover {
        height: 0%;
    }
    .container {
        height: 200px;
    }

    .spinner {
        position: absolute;
        top: 30px;
        left: 40%;
    }
}

@media only screen and (max-width: 260px) {

    .heroDescription {
        transform: translateY(-10%);
    }
    .videoCover {
        height: 0%;
    }
    .container {
        height: 200px;
    }
}
