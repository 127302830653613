.arrow {
    display: block;
}

@media screen and (max-width: 1024px) {
    .arrow {
        display: none;
    }
}

