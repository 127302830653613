@import '~@csstools/normalize.css';
*, *::after, *::before {
  box-sizing: border-box;
}

:root {
  --main-container: 1220px;
  --color-light-grey: #444444;
  --color-social-hover: #bfcaca;
  --menu-border: #c6102e;
  --color-main-theme: #c6102e;
  --color-main-bubble: #ececec;
  --color-footer-gradient-start: #737373;
  --color-footer-gradient-finish: #585858;
  --color-footer: #b4b4b4;
   color-scheme: light only;
  --color-dark-grey: #646464;
  --color-grey-table:  #8d8d8d;
  /*---------------------------------*/

}

body {
  background-color: white !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: light only !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-ixblex).ant-drawer-left>.ant-drawer-content-wrapper {
  box-shadow: none !important;
}


