.App {
  text-align: center;
}

.hero {
  z-index: -1000;

}

.swiperDivider {
    margin-bottom: 15px;
 padding-bottom: 25vh;
/*background-color: white;*/

  z-index: 1000;
}
@media only screen and (max-width: 380px) {
  .swiperDivider {
    padding-bottom: 60%;
  }
}
/*
@media only screen and (max-width: 820px)  {
 .swiperDivider {
   margin-bottom: 120px
 }
}

@media only screen and (max-width: 540px) {
    .swiperDivider {
        margin-bottom: 80px
    }
}
*/
