.container {
    max-width: var(--main-container);
    padding: 20px 10px 10px;
    margin: auto auto -100px;
    position: relative;
}

.gallery {
    display: flex;
    flex-direction: row;
    margin: auto auto 10px;
    justify-content: space-between;
}

.smallIcon {
    width: 30px;
    height: 30px;
}
.cardLeftImage {
    display: flex;
    align-items: center;
}
.bold {
    font-weight: 800;
}
.cityName{
padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    font-size: clamp(12px, 1.5vw, 18px);
    white-space: nowrap;
}

.time {
    font-size: clamp(12px, 1.5vw, 18px);
    white-space: nowrap;
}

.cityDes {
display: flex;
    flex-direction: column;
    width: 70%;
    flex-wrap: nowrap;

}

.imageCont {
    display: flex;
    width: 33%;
    /*cursor: pointer;*/
}

.image {
    width: 100%;
    cursor: pointer;
}
.activeImageCont {
    display: flex;
    max-height: 520px;
    /*height: 100%;*/
    width: 100%;
    margin: auto auto 10px;
}

.activeImageCont .image {
    cursor: auto;
    object-fit: cover;
}


.description {
    font-size: clamp(12px, 2vw, 20px);
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin: auto auto 20px;
    line-height: 30px;
    border-collapse: collapse;

}
.galleryContainer {
    display: block;
}

tr:first-child {
    border-top: 1px solid var(--color-main-theme)
}

tr {
    border-bottom: 1px solid var(--color-main-theme);
    border-left: 1px solid var(--color-main-theme);
    border-right: 1px solid var(--color-main-theme)
}

.swiperContainer {
    display: none;
}

.cell {
    padding-left: 5px;
    width: 25%;
}

.cell:not(:last-child) {
    border-right: 1px solid var(--color-main-theme);
}

.tableHeader {
    text-align: center;
    font-size: clamp(12px, 2vw, 18px);
    font-weight: 600;
    background-color: #dfd8d8;
}

.tableHeader:not(:last-child) {
    border-right: 1px solid var(--color-main-theme);
}

.cellContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    .activeImageCont {
        max-height: 50vh;
    }
    .smallIcon {
        width: 20px;
        height: 20px;
    }
}
@media screen and(max-width: 920px) {

    .container {
        padding-top: 0;
    }

}
@media screen and (max-width: 640px) {
    .galleryContainer {
        display: none;
    }
    .swiperContainer {
        display: block;
    }
    .container {
        margin-bottom: -100px;
    }
    .smallIcon {
        height: 15px;
        width: 15px;
        display: none;
    }
    .cellContent {
        flex-direction: column;

    }
    .cell {
        vertical-align: top;

    }
    .cityName {
        white-space: break-spaces;
    }

}
@media screen and (max-width: 330px) {
    .cityName {
        font-size: 10px;
    }
    .description {
        font-size: 8px;
        font-weight: 800;
    }
}
