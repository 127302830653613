.footerMain {
    position: relative;
    /*background: #585858;
    background: currentColor;**/
}
.container {
    text-align: center;
    padding: 150px 0  0 0;
    color: #b4b4b4;
    white-space: nowrap;
    position: absolute;
    width: 100%;
    /*background: linear-gradient(var(--color-footer-gradient-start), var(--color-footer-gradient-finish));*/
    background-image: url("../../assets/images/footer_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 10;
    transform: translateY(-225px);
    min-height: 550px;

}


.innerContainer {
    justify-content: center;
    display: flex;
    flex-direction: row;
    max-width: 1920px;
    margin: auto;
    text-align: center;
}


.logoImprintSection {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
}
.mainLinkSection {
    display: flex;
    align-items: center;
    text-align: left;
}
.mainLink {
    width: 50%;
}

.imprintSection {
    display: flex;
    flex-direction: column;

}

.btn {
    background-color: transparent;
    color: var(--color-footer);
    border: none;
    cursor: pointer;
    font-size: clamp(12px, 2vw, 28px);
    position: relative;
    margin-bottom: 10px;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.linkContainer {
    text-align: left;
    align-self: flex-start;
}

.btn::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: -30px;
    top: 50%;
    border: 10px solid var(--color-main-theme);
    z-index: 15;
    transform: translate(50%, -50%);
    border-color:  transparent transparent  transparent var(--color-main-theme);
    transition: all .3s ease-in-out;
}

.btnOpen.btn::before {
    transform: rotate(90deg) translate(-5px, -5px);

}

.socialLink{
    width: clamp(30px, 2vw, 50px);
    height: clamp(30px, 2vw, 50px);
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
    border: 1px solid #ffffff;
    border-radius: 50%;


}

.socialLink:hover {
   border-color: var(--color-main-theme);
}

.contactsSection {
    font-size: clamp(12px, 2vw, 28px);
    display: flex;
    flex-direction: column;

    align-self: center;
}

.contactsSection p {
    margin-top: 0;
    margin-bottom: 20px;
}
.contactLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.numbers {
    color: white;

    width: 50%;
    text-align: left;
    text-decoration: none;
}

@media only screen and (max-width: 3806px)  {
    .container {
        transform: translateY(-65px);
        padding: 100px 0 0 0;
        min-height: 320px;
    }
}

@media screen and (max-width: 820px) {
    .container {
        transform: translateY(-10px);
        padding: 80px 0 0 0;
        min-height: 250px;
    }
}

@media only screen and (max-width: 540px)  {
    .container {
        padding: 50px 10px 0 10px;
    }
    /*.mainLink {
        display: none;
    }*/
    .logoImprintSection{

        margin: 0 auto 10px auto;
        justify-content: center;
        width: 100%;
            }

    .mainLinkSection {
        text-align: center;
        display: block;
    }
    .innerContainer {
        justify-content: center;
        flex-direction: column;
    }

    .contactsSection {
        align-self: center;
        padding: 0 5px 0 0;
        width: 50%;
    }

    .container{ background-size: cover;
    background-position-x: 50%;
    }
}
