.container {
    padding-top: 25px;
    z-index: 2;
    position: relative;
    margin: -1% auto auto;
}

.div1 {
    width: 100%;
    height: 25px;
    background-color: #ededed;
    position: absolute;
    z-index: 15;
    top: 0;
    border-top-right-radius: 80% 50%;
    border-top-left-radius: 80% 80%;
}




.navButton {
    position: absolute;
    top:0;
    z-index: 15;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.nextButton {
    right: 0;
    z-index: 15;
}

.buttonLayer {
    cursor: pointer;
    background-color: white;
    height: 100%;
    padding-left: 50px;
    opacity: .5;
    z-index: 15;
}
.arrow {
    cursor: pointer;
    position: absolute;
    border: 10px solid var(--color-main-theme);
    z-index: 15;
}

.leftArrow {
    transform: translateX(50%);
    border-color:  transparent var(--color-main-theme) transparent  transparent ;
    z-index: 15;
}

.rightArrow {
    transform: translateX(100%);
    border-color:  transparent  transparent  transparent var(--color-main-theme);
    z-index: 15;
}

@media only screen and (max-width: 1024px)  {
    .navButton {
        display: none;
    }
}


