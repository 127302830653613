.container {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 200;
    position: relative;
}
.innerContainer {
    max-width: var(--main-container);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.nav {

    padding: 5px 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    background-color: white;
    z-index: 1000;
}

.navWrap {
    width: 100%;
    z-index: 1;
}

.homeIcon {
    width: 15px;
    height: 13px;
    margin-right: 2px;
    vertical-align: baseline;
}
.link {
    color: var(--color-light-grey);
    text-decoration: none;
    font-size: clamp(12px, 1.3vw, 18px);
    font-weight: 600;
    padding: 0 10px;
    transition:all .3s ease-in-out;
    border-bottom: none;
}

.link:hover {
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.05);
}



.link:not(:nth-last-child(1)) {
    border-right: 2px solid var(--menu-border);

}

.serviceMenu, .productsMenu {

    z-index: -10;
    background-color: white;
    top: 100%;

    justify-content: center;
    position: absolute;
    visibility: hidden;
    transition: all .3s ease-in-out;
    opacity: 0;
    max-width: 650px;
    width: 37%;
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
    border-right: 1px solid var(--color-light-grey);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}


.serviceMenuShow, .productsMenuShow{
    visibility: visible;
    opacity: 1;
    transition: all .3s ease-in-out;
}

.languageSelector {
    display: inline;
    /*transform: translateY(10px);*/
}
.menuContainer {

    flex-direction: row;
}
@media only screen and (max-width: 768px) {
    /*.innerContainer {display: none}*/
}

.burger {
   /* left: 20px;*/
    width: 36px;
    height: 28px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    border: none;
    padding: 0;
    position: relative;
    transition: visibility .3s ease-in-out, opacity .3s ease-in-out, transform 3s ease-in-out;
    cursor: pointer;
    box-shadow: none
;

}

.burgerSpan {
    padding: 0;
    background-color:var(--color-dark-grey);
    height: 4px;
    width: 100%;
    transition: transform .3s ease-in-out, width .3s ease-in-out, opacity .2s ease-in-out;

}

.open.burgerSpan:first-child {

    transform: rotate(-45deg) translate(-9px, 9px);
}

.open .burgerSpan:nth-child(2) {
    opacity: 0;
}

.open .burgerSpan:last-child {
    transform: rotate(45deg) translate(-7px, -8px);
}

.burgerBtn {
    /*position: absolute;*/
    z-index: 100;
}

.mobileMenu {
    display: none;
}

.desktopMenu {
    display: flex;
}

.mobileHomeLink {
    display: none;
}



@media screen and (max-width: 1024px) {
    .desktopMenu{
        display: none;
    }

    .mobileMenu {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .innerContainer {
        max-width: var(--main-container);

        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .homeIconMob {
width: 32px;
        margin: auto;
        height: 23px;
    }

    .homeIcon {
        display: none;
    }

    .mobileHomeLink {
        display: block;
    }

.nav {
    position: relative;
    max-height: 60px;
    padding: 15px 10px;
}
.navWrap {
    position: relative;
}
    .drawer {
margin-top: 50px;
        z-index: 0;

    }

    .menuContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        justify-content: space-between;
        max-height: 50%;
        text-align: center;
    }


    .link {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 0;
        transition:all .3s ease-in-out;
        border-bottom: 1px solid var(--color-main-theme);

    }

    .link:not(:nth-last-child(1)) {
        border-right: none;

    }
}


.innerDrawer {

    background: linear-gradient(#ffffff, #868686) !important;
    border-radius: 5px;

}



