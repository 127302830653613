.container {
    max-width: var(--main-container);
    padding: 20px  10px 10px;
    margin: auto auto -100px;
    position: relative;
}

.gallery {
    display: flex;
    flex-direction: row;
    margin: auto auto 10px;
    justify-content: space-between;
}


.imageCont {
    display: flex;
    width: 33%;
    /*cursor: pointer;*/
}

.image {
    width: 100%;
    cursor: pointer;
}
.activeImageCont {
    display: flex;
    /*max-height: 520px;*/
    /*height: 100%;*/
    width: 100%;
    margin: auto;
}

.activeImageCont .image {
    cursor: auto;
    object-fit: contain;
}

.list {
    font-size: clamp(12px, 2vw, 20px);
    font-weight: 600;
    text-align: left;
}

.description {
    font-size: clamp(12px, 2vw, 20px);
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin: auto auto 20px;
    line-height: 30px;
    border-collapse: collapse;

}
.galleryContainer {
    display: block;
}

.swiperContainer {
    display: none;
}

.cell {
    padding-left: 5px;
    width: 25%;
}


@media screen and (max-width: 1024px) {
    .activeImageCont {
        max-height: 50vh;
    }

}

@media screen and(max-width: 920px) {

    .container {
        padding-top: 0;
    }

}


@media screen and (max-width: 640px) {
    .galleryContainer {
        display: none;
    }
    .swiperContainer {
        display: block;
    }
    .container {
        margin-bottom: -100px;
    }

}
@media screen and (max-width: 330px) {
    .description {
        font-size: 12px;

    }
}
