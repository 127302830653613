.swiperContainer {
    margin: auto;
    /*padding-top: 25px;*/
    z-index: 0;
    top: 0;
    left: 0;
    opacity: 0;
    /*position: absolute;*/
    width: 0;
    height: 0;
}

@media screen and (max-width: 640px) {

    .swiperContainer {
        opacity: 1;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }

}


